<template>
  <div>
    <OrderPage></OrderPage>
  </div>
</template>

<script>
import OrderPage from '@/components/shop/OrderPage.vue'

export default {
  components: {OrderPage}
}
</script>